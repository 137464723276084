<template>
    <div>
       
        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.name = v; delete serverErrors.name; delete serverErrors.slug}" 
                    :valueInput="values.name" 
                    :rule="[...rules.required]" 
                    label="Nombre"
                    :errorMessages="serverErrors.name||serverErrors.slug"
                    data-cy="name-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.description = v; delete serverErrors.description; delete serverErrors.slug}" 
                    :valueInput="values.description" 
                    :rule="[...rules.required]" 
                    label="Descripción"
                    :errorMessages="serverErrors.description||serverErrors.slug"
                    data-cy="description-input"
                   
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2 mb-5">
                <textFieldForm
                    @valueChange="(v) => {values.price = v}" 
                    :valueInput="values.price" 
                    :rule="rules.cost" 
                    label="Precio"
                    :errorMessages="serverErrors.price"
                    :prefix="values.price == '' ? '' : '$'"
                    data-cy="price-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
               
                cost: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
				
				
            },

            

          
        }
    },
   
    mounted(){
    },
    methods: {
        
       
    }
}
</script>

<style>

</style>